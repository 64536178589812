import React from "react"

const SpinnerButton= (props)=> {
    return (
           <div >
            <button onClick={props.onclick}   className={props.className||''}
                type ={props.type||''}
                disabled={props.isProcessing}>
                {props.isProcessing && <span>{props.processingMsg || 'Processing..'}</span>}
                {!props.isProcessing && <span>{props.actionMsg || 'Processed'}</span>}
                {props.isProcessing && (
                    <div className="spinner-border"></div>
                    )
                }
            </button>
        </div>
  )
}


export default SpinnerButton;