import React, { useState, useRef } from "react"
import { Formik, Form, Field, FieldArray } from 'formik';
import * as yup from 'yup';
import NumberFormat from 'react-number-format';
import SpinnerButton from './spinner-button';



export const ReserveTable = ({ formData, setFormData, nextStep }) => {

    const [isProcessing, setIsProcessing] = useState(false);
    const [formError, setFormError] = useState('');
    const [dealerDonationError, setDealerDonationError] = useState('');
    const [dealerBriefingError, setDealerBriefingError] = useState('');
    const arrayHelperRef = useRef();


    const CurrencyNumberField = ({ field }) => {
        return <NumberFormat {...field} decimalScale={0} allowLeadingZeros={false} allowEmptyFormatting={true} allowNegative={false} prefix={'$ AU '} />;
    }
    const NumberField = ({ field }) => {
        return <NumberFormat {...field} decimalScale={0} allowLeadingZeros={false} allowEmptyFormatting={true} allowNegative={false} />;
    }
    const CustomError = ({ errorText, show }) => {
        return (
            <> {show ? (
                <label className="form-field-error">{errorText}</label>
            ) : null}
            </>
        )
    }

    const urlValidationString = /^((ftp|http|https):\/\/)?(www.)?(?!.*(ftp|http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+((\/)[\w#]+)*(\/\w+\?[a-zA-Z0-9_]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/gm

    const validationSchema = yup.object({


        firstName: yup
            .string()
            .required('Given Name(s) is required')
            .max(50, 'Given Name(s) must be at most 50 characters'),
        familyName: yup
            .string()
            .required('Family Name is required')
            .max(50, 'Family Name must be at most 50 characters'),
        email: yup
            .string()
            .email('Invalid email')
            .required('Email is required'),
        position: yup
            .string()
            .required('Position is required')
            .max(30, 'Position must be at most 30 characters'),
        company: yup
            .string()
            .required('Company is required')
            .max(50, 'Company must be at most 50 characters'),
        companyAddress: yup
            .string()
            .required('Company Address is required')
            .max(100, 'Company Address must be at most 100 characters'),
        phone: yup
            .number().typeError('Phone should be a number')
            .required('Phone number is required'),
        noOfTables: yup
            .number().typeError('Should be a number')
            .min(0, 'Cannot be less than 0'),
        noOfGuests: yup
            .number().typeError('Should be a number')
            .min(0, 'Cannot be less than 0'),
        stayHomeAmount: yup
            .string().typeError('Should be a number')
            .min(0, 'Cannot be less than 0'),
        website: yup
            .string()
            .url('Website is not valid'),
        businessGuests: yup.array()
            .of(yup.object().shape(
                {
                    businessGuestFirstName: yup.string().max(100),
                    businessGuestFamilyName: yup.string().max(100)
                }
            ))
    });


    const getTotalDonation = (values) => {

        if (values.formType === "supplier") {
            return ((values.noOfTables || 0) * values.costPerTableSupplier) +
                ((values.noOfGuests || 0) * values.costPerGuestSupplier) +
                Number(Number(values.stayHomeAmount) || 0)

        } else if (values.formType === "dealer") {
            return ((values.noOfTables || 0) * values.costPerTableDealer) +
                ((values.noOfGuests || 0) * values.costPerGuestDealer) +
                Number(Number(values.stayHomeAmount) || 0) +
                Number(Number(values.dealerDonationAmount) || 0)
        }
    }
    const getTaxDeductible = (values) => {

        if (values.formType === "supplier") {

            return ((values.noOfTables || 0) * values.taxDeductablePerTableSupplier) +
                ((values.noOfGuests || 0) * values.taxDeductablePerGuestSupplier) +
                Number(Number(values.stayHomeAmount) || 0)

        } else if (values.formType === "dealer") {

            return ((values.noOfTables || 0) * values.taxDeductablePerTableDealer) +
                ((values.noOfGuests || 0) * values.taxDeductablePerGuestDealer) +
                Number(Number(values.stayHomeAmount) || 0) +
                Number(Number(values.dealerDonationAmount) || 0)
        }

    }


    const getFormReset = (values) => {

        values.firstName = ''
        values.familyName = ''
        values.company = ''
        values.position = ''
        values.companyAddress = ''
        values.email = ''
        values.phone = ''
        values.website = ''
        values.noOfTables = '0'
        values.noOfGuests = '0'
        values.businessGuests = [];
        values.stayHomeAmount = ''
        values.dealerDonationAmount = ''
        values.radioAttendYesNo = ''
        setFormData(values);
    }

    return (
        <Formik
            initialValues={formData}

            onSubmit={values => {
                if (values.noOfTables < 1 && Number(values.stayHomeAmount) < 1 && values.noOfGuests < 1) {
                    setFormError('Please fill in at least "How many tables" or "How many seats" or "Stay-at-home supporter" questions.');
                    return;
                }

                if (values.formType === "dealer" && values.businessGuests.length < 1 && !values.radioAttendYesNo) {
                    setDealerBriefingError('Please select either "I will be attending the business briefing" or "Cannot attend the Dealer business briefing"');
                    return;
                } else if (values.formType === "dealer" && values.radioAttendYesNo === 'Yes' && values.businessGuests.length === 0) {
                    setDealerBriefingError('Please enter guest details');
                    return;
                } else if (values.formType === "dealer" && values.businessGuests.length > 0) {
                    var emptyItemFound = false;
                    values.businessGuests.map((guest, index) => {

                        if ((guest.businessGuestFirstName.trim().length === 0) || (guest.businessGuestFamilyName.trim().length === 0)) {
                            emptyItemFound = true;
                        }
                    });
                    if (emptyItemFound) {
                        setDealerBriefingError('Please enter correct guest details');
                        return;
                    }
                }

                values.totalPay = getTotalDonation(values);
                values.totalPayWithSurcharge = values.totalPay;
                values.taxDeductibleAmount = getTaxDeductible(values);
                setIsProcessing(true);
                setFormData(values);
                nextStep();
            }}

            validationSchema={validationSchema}
        >
            {({ errors, touched, resetForm, values }) => (
                <Form >

                    <div >
                        <div className="container">
                            <div id="donorDetails" className="center">
                                <h1>SECURE TABLE</h1>
                            </div>

                            <div className="">

                                <div className="one_half column">
                                    <div className="columns ">
                                        <div className=" column ">
                                            <p>I'm pleased to accept your invitation to the Mazda Foundation Gala Dinner 2024.</p>
                                            <br />
                                        </div>
                                        <div className="one_half column">
                                            <p> Given name(s)<br />
                                                <Field style={{ borderColor: errors.firstName ? ' #8d061c' : '' }}
                                                    name='firstName'
                                                    label='First Name'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.firstName} show={touched.firstName} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p> Family Name<br />
                                                <Field style={{ borderColor: errors.familyName ? ' #8d061c' : '' }}
                                                    name='familyName'
                                                    label='Family Name'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.familyName} show={touched.familyName} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p>Company<br />
                                                <Field style={{ borderColor: errors.company ? ' #8d061c' : '' }}
                                                    name='company'
                                                    label='Company'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.company} show={touched.company} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p >
                                                Position
                                                <Field style={{ borderColor: errors.position ? ' #8d061c' : '' }}
                                                    name='position'
                                                    label='Position'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.position} show={touched.position} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p>Company Address<br />
                                                <Field style={{ borderColor: errors.companyAddress ? ' #8d061c' : '' }}
                                                    name='companyAddress'
                                                    id='companyAddress'
                                                    label='Company Address'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.companyAddress} show={touched.companyAddress} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p>Email<br />

                                                <Field style={{ borderColor: errors.email ? ' #8d061c' : '' }}
                                                    name='email'
                                                    label='Email Address'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.email} show={touched.email} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p>Phone<br />
                                                <Field style={{ borderColor: errors.phone ? ' #8d061c' : '' }}
                                                    name='phone'
                                                    label='Phone'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.phone} show={touched.phone} />
                                            </p><br />
                                        </div>
                                        <div className="one_half column">
                                            <p>Website <small>(Eg: https://www.mazda.com.au)</small><br />

                                                <Field style={{ borderColor: errors.website ? ' #8d061c' : '' }}
                                                    name='website'
                                                    label='Website'
                                                    margin='normal'
                                                    type="text"
                                                />
                                                <CustomError errorText={errors.website} show={touched.website} />
                                            </p><br />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {formData.formType === "dealer" &&
                                <div className="columns ">
                                    <div className="column" >
                                        <p>How many tables would you like to book?<br />
                                            <small>1 table of 10 guests. $5,690 per table including GST, including a tax deductible donation of $2,500.<br />
                                            </small>
                                            <div className="one_fourth column" style={{ paddingLeft: "0" }} >
                                                <Field name="noOfTables" component={NumberField} style={{ borderColor: errors.noOfTables ? ' #8d061c' : '' }} />
                                            </div>
                                            <CustomError errorText={errors.noOfTables} show={touched.noOfTables} />
                                        </p><br />
                                    </div>
                                    <div className="column">
                                        <p>How many additional seats would you like to book? (Optional)<br />
                                            <small>${formData.costPerGuestDealer} per guest including GST, including a tax deductible donation of $250.<br />
                                            </small>
                                            <div className="one_fourth column" style={{ paddingLeft: "0" }} >
                                                <Field name="noOfGuests" component={NumberField} style={{ borderColor: errors.noOfGuests ? ' #8d061c' : '' }} />
                                            </div>
                                            <CustomError errorText={errors.noOfGuests} show={touched.noOfGuests} />
                                        </p><br />
                                    </div>

                                    <div className="column">
                                        <p>To help spread the fundraising load please advise if you would like to provide an additional optional tax-deductible donation appropriate to your dealership category.</p>
                                        Please select your category below.<br />
                                        <label className="label-text">
                                            <Field type="radio" id="metropolitanDealerDonation" name="dealerDonationAmount" value={formData.metropolitanDealerDonation} className="radio-align" />
                                            Metropolitan category - Donation of $10,000
                                        </label> <br />
                                        <label className="label-text">
                                            <Field type="radio" id="provincialDealerDonation" name="dealerDonationAmount" value={formData.provincialDealerDonation} className="radio-align" />
                                            Provincial category - Donation of $5,000
                                        </label><br />
                                        <label className="label-text">
                                            <Field type="radio" id="ruralDealerDonation" name="dealerDonationAmount" value={formData.ruralDealerDonation} className="radio-align" />
                                            Rural category - Donation of $2,500
                                        </label>
                                        <br />
                                        <p className="form-field-error">{dealerDonationError}</p><br />
                                    </div>

                                </div>
                            }

                            {formData.formType === "supplier" &&
                                <div className="columns ">
                                    <div className="column" >
                                        <p>How many tables would you like to book?<br />
                                            <small>1 table of 10 guests. $5,690 per table including GST, including a tax deductible donation of $2,500.<br />
                                            </small>
                                            <div className="one_fourth column" style={{ paddingLeft: "0" }} >
                                                <Field name="noOfTables" component={NumberField} style={{ borderColor: errors.noOfTables ? ' #8d061c' : '' }} />
                                            </div>
                                            <CustomError errorText={errors.noOfTables} show={touched.noOfTables} />
                                        </p><br />
                                    </div>
                                    <div className="column">
                                        <p>How many additional seats would you like to book? (Optional)<br />
                                            <small>${formData.costPerGuestSupplier} per guest including GST, including a tax deductible donation of $250.<br />
                                            </small>
                                            <div className="one_fourth column" style={{ paddingLeft: "0" }} >
                                                <Field name="noOfGuests" component={NumberField} style={{ borderColor: errors.noOfGuests ? ' #8d061c' : '' }} />
                                            </div>
                                            <CustomError errorText={errors.noOfGuests} show={touched.noOfGuests} />
                                        </p><br />
                                    </div>
                                </div>
                            }

                            <div className="columns ">
                                <div className="column">
                                    <p>Stay-at-home supporter (Optional)</p>
                                    <small>
                                        I'm unable to attend but would like to make a tax deductible donation.<br />
                                    </small>
                                    <label >
                                        <Field type="radio" name="stayHomeAmount" value={formData.stayHomeAmountOption1} className="radio-align" />
                                        $10,000
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label >
                                        <Field type="radio" name="stayHomeAmount" value={formData.stayHomeAmountOption2} className="radio-align" />
                                        $5,000
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label >
                                        <Field type="radio" name="stayHomeAmount" value={formData.stayHomeAmountOption3} className="radio-align" />
                                        $2,500
                                    </label>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                    <label >
                                        <Field type="radio" name="stayHomeAmount" value={formData.stayHomeAmountOption4} className="radio-align" />
                                        $1,000
                                    </label>
                                    <br />
                                    <p className="form-field-error">{formError}</p><br />
                                </div>

                            </div>
                            {
                                formData.formType === "dealer" &&
                                <div className="columns ">
                                    <div className="column">
                                        <p>Dealer Business Briefing Guest Details. </p>
                                        <p>Please list the names of attendees for the Dealer Business Briefing. </p>
                                        <small> Please note – The business briefing session is only applicable for Dealer management staff that are attending the Mazda Foundation Gala or Dealerships making a stay at home donation.</small>
                                    </div>

                                    <div className="column">
                                        <label className="CheckBoxLabel">
                                            <Field type="radio" name="radioAttendYesNo" className="CheckBoxStyle" value={formData.radioAttendYes}
                                                onClick={(e) => {
                                                    if (values.businessGuests.length === 0) {
                                                        arrayHelperRef.current.push({ businessGuestFirstName: '', businessGuestFamilyName: '' })
                                                    }
                                                }
                                                }
                                            />
                                            I will be attending the business briefing
                                        </label>
                                    </div>

                                    <FieldArray
                                        name="businessGuests"
                                        render={arrayHelpers => {
                                            arrayHelperRef.current = arrayHelpers
                                            return (

                                                <div className="columns">
                                                    {
                                                        values.businessGuests.map((businessGuest, index) => (
                                                            <div key={index} className="column ">

                                                                <div className="guest-add-section">
                                                                    <div >
                                                                        <div className="columns ">
                                                                            <div className="one_half column">
                                                                                <p > Given Name(s)<br />
                                                                                    <Field name={`businessGuests[${index}].businessGuestFirstName`} type="text" />
                                                                                    {errors.businessGuests?.length > 0 ? <CustomError index={index} errorText={errors.businessGuests[index]?.businessGuestFirstName} show={touched?.businessGuests?.length > 0 && errors.businessGuests[index]?.businessGuestFirstName} /> : <></>}
                                                                                </p>
                                                                            </div>
                                                                            <div className="one_half column">
                                                                                <p  > Family Name<br />
                                                                                    <Field name={`businessGuests[${index}].businessGuestFamilyName`} type="text" />
                                                                                    {errors.businessGuests?.length > 0 ? <CustomError index={index} errorText={errors.businessGuests[index]?.businessGuestFamilyName} show={touched?.businessGuests?.length > 0 && errors.businessGuests[index]?.businessGuestFamilyName} /> : <></>}
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div>

                                                                        <div className="columns dietary-req">
                                                                            <div className="one_half column">
                                                                                <p className="position-margin"></p>
                                                                            </div>
                                                                            <div className="one_half column remove-guest-head ">
                                                                                {<a className="remove-guest add-guest-text" href='#' onClick={(e) => { e.preventDefault(); arrayHelpers.remove(index) }}>
                                                                                    Remove Guest
                                                                                </a>}

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        ))}

                                                    {
                                                        values.businessGuests.length > 0 &&
                                                        values.businessGuests.length < 5 &&
                                                        <div className="column">
                                                            <a href='#' className="add-guest-text"
                                                                onClick={(e) => { e.preventDefault(); arrayHelpers.push({ businessGuestFirstName: '', businessGuestFamilyName: '' }) }}
                                                            >
                                                                Add Another guest
                                                            </a><br /><br />
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                        }
                                    />

                                    <div className="column">
                                        <label className="CheckBoxLabel">
                                            <Field type="radio" name="radioAttendYesNo" className="CheckBoxStyle" value={formData.radioAttendNo}
                                                onClick={(e) => { values.businessGuests = []; }}
                                            />
                                            Cannot attend the Dealer business briefing
                                        </label><br /><br />
                                        <p className="form-field-error">{dealerBriefingError}</p><br />
                                    </div>
                                </div>

                            }
                            {(formError || dealerDonationError || errors.firstName || errors.familyName || errors.company || errors.position || errors.companyAddress
                                || errors.email || errors.phone || errors.website || errors.noOfTables || errors.noOfGuests) &&
                                <div className="columns">
                                    <div className="column">
                                        <p className="form-field-error">Please complete the form correctly to proceed</p> <br />
                                    </div></div>
                            }
                            {(formData.formType === "dealer" || formData.formType === "supplier") &&
                                <div className="columns" >
                                    <div style={{ display: "flex", flexDirection: "raw" }}>
                                        <div style={{ paddingLeft: "1em" }}>
                                            <SpinnerButton
                                                type="submit"
                                                className='proceed-pay'
                                                isProcessing={isProcessing}
                                                processingMsg='Processing'
                                                actionMsg='PROCEED TO PAYMENT'
                                            ></SpinnerButton>

                                        </div>

                                        <div style={{ paddingLeft: "1em" }} >
                                            <button className="GreyButton" type="reset" onClick={(e) => { getFormReset(values) }}>Reset</button>
                                        </div>
                                    </div>

                                </div>
                            }
                        </div>

                    </div>
                </Form>
            )
            }
        </Formik>
    )
}
