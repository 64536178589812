import React, { useState } from "react"


export const EventDescription=({EventId})=>{
return (
     
 
 
        <div >
          <div className="container center">

                <div id="event-dinner" className="horizontal-padding">
                <p><br/>
                Mazda Australia would like to formally invite you to join us at the next Mazda Foundation Gala Dinner.
                <br/>
                <br/>
                As a valued member of our generous Mazda family, we'd like to give you the opportunity to purchase a table and secure your place at this prestigious event. You'll enjoy an evening of entertainment, world-class food, auctions and raffles featuring local produce and destinations, plus much more.
                <br/>
                <br/>
                The event will be held on Friday &nbsp;18<sup>th</sup> October 2024.
                <br/>
                <br/>
                All funds raised from the evening will go to the valuable work the Mazda Foundation does, supporting Australian organisations working in the areas of youth, education and excellence, the environment and medical research.
                <br/>
                <br/>
                We hope to see you there for this special night.
                </p>
                </div>
                <div id="title" className="event-bg  center">
              <div className="container">
                <div className="horizontal-padding"><br/> 
                  <h1>EVENT DETAILS</h1>
                  <p><b>WHEN: </b> Friday,&nbsp;18<sup>th</sup> October 2024
                    <br/><br/>
                    <b>TIME: </b> 7pm Start
                    <br/><br/>
                    <b>WHERE:</b>&nbsp;&nbsp;Palladium Ballroom,<br/> Crown Casino Melbourne. 
                    <br/><br/>
                    <b>DRESS:</b>&nbsp;&nbsp;Black Tie
                    <br/><br/>
                    <b>RSVP:</b>&nbsp;&nbsp;Friday,&nbsp;6<sup>th</sup> September 2024<br/>
                  </p>
                  <br/>
                </div>
              </div>
            </div>
            </div>
        </div>
        
       )
}
 