import React, { useState, useEffect } from "react";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import { StripeCard } from "./stripe-card";

const stripePromise = loadStripe(process.env.GATSBY_PUBLIC_KEY);

export const StripePayment = ({ formData,setFormData,nextStep}) => {
    
    return (
        <Elements stripe={stripePromise}>
            <StripeCard formData={formData}  setFormData={setFormData} nextStep={nextStep} />
        </Elements>
    )
}
