import React, { useState, useEffect } from "react";
import { Elements, CardElement, CardNumberElement, CardExpiryElement, CardCvcElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import axios from "axios";
import NumberFormat from 'react-number-format';
import SpinnerButton from './spinner-button';
export const StripeCard = ({ formData,setFormData,nextStep}) => {

const FORM = "https://docs.google.com/forms/u/0/d/e/1FAIpQLSetHtVPezm8tQVKalS9Sl1b8In2QxxurD0Yt5oxjQXRDmyMMw/formResponse"
const GF_FirstName_Field = "entry.871054279"
const GF_FamilyName_Field = "entry.676351492"
const GF_Company_Field = "entry.1654665055"
const GF_CompanyAddress_Field = "entry.1354901741"
const GF_Email_Field = "entry.229440573"
const GF_GuestFirstName_Field = "entry.1269240881"
const GF_GuestFamilyName_Field = "entry.587959010"


  const stripe = useStripe();
  const elements = useElements();
  const [cardError,setCardError]=useState('');
  const [isProcessing,setIsProcessing]=useState(false);
  const [isAmex,setIsAmex]=useState(false);
  const [sumWithSurcharge,setSumWithSurcharge]=useState(formData.totalPay);
  const onChange=(e)=>{
    setCardError(e.error?e.error.message:'');
    setSumWithSurcharge(formData.totalPay);
    setIsAmex(e.brand === 'amex');
    if(e.brand === 'amex'){
      setSumWithSurcharge(((formData.totalPay||0)*102)/100);
    }

    setFormData(formData);
  }

  const updateBusinessGuestGoogleForm = (formData) => {
   
    formData.businessGuests.map((guest, index) => {
     
    if((guest.businessGuestFirstName!="")||(guest.businessGuestFamilyName!="")){
        const submitFormData = new FormData()
        submitFormData.append(GF_FirstName_Field, formData.firstName)
        submitFormData.append(GF_FamilyName_Field, formData.familyName)
        submitFormData.append(GF_Company_Field, formData.company)
        submitFormData.append(GF_CompanyAddress_Field, formData.companyAddress)
        submitFormData.append(GF_Email_Field, formData.email)
        submitFormData.append(GF_GuestFirstName_Field, guest.businessGuestFirstName)
        submitFormData.append(GF_GuestFamilyName_Field, guest.businessGuestFamilyName)
        
        axios
          .post(FORM, submitFormData)
    }
    });
  }
  const env = process.env.NODE_ENV
  const makePayment = (e) => { 
    
    if(!elements.getElement('card')._complete){
      setCardError('Please complete Card details');
      return;
    } 
    if(formData.cardHolderName.trim()==='')
    {
      setCardError('Please enter value in Name on Card field');
      return;
    }
    
    axios.defaults.baseURL = process.env.GATSBY_API_URL;
    e.preventDefault();
    setIsProcessing(true);
    var paymentRequest = {
      "CustomerDetails":{
        GivenNames:formData.cardHolderName,
        EmailAddress:formData.email,
        Phone:formData.phone,
      },   
      "PaymentAmount": sumWithSurcharge||0,
      "RequestAccount": "MazdaFoundation",
      "Description": 
      
      `Booking Summary \n 
                            ${
                              formData.noOfTables > 0 ? 
                              `Tables : ${formData.noOfTables} * ${Number(formData.formType==="dealer"?formData.costPerTableDealer
                                                                          :formData.formType==="nodonation"?formData.costPerTableNoDonation
                                                                          :formData.formType==="supplier"?formData.costPerTableSupplier
                                                                          :0).toFixed(2)}
                                                                                      
                              = ${Number(formData.noOfTables * (formData.formType==="dealer"?formData.costPerTableDealer
                                                               :formData.formType==="nodonation"?formData.costPerTableNoDonation
                                                               :formData.formType==="supplier"?formData.costPerTableSupplier
                                                               :0)).toFixed(2)} \n`
                            : ``}
                          
                            ${formData.noOfGuests > 0 ? `Guest : ${formData.noOfGuests} * ${Number(formData.formType==="dealer"?formData.costPerGuestDealer
                                                                                                    :formData.formType==="nodonation"?formData.costPerGuestNoDonation
                                                                                                    :formData.formType==="supplier"?formData.costPerGuestSupplier
                                                                                                    :0).toFixed(2)} 
                            = ${Number(formData.noOfGuests * (formData.formType==="dealer"?formData.costPerGuestDealer
                                                            :formData.formType==="nodonation"?formData.costPerGuestNoDonation
                                                            :formData.formType==="supplier"?formData.costPerGuestSupplier
                                                            :0)).toFixed(2)} \n` 
                            : ``}                
                            ${formData.stayHomeAmount > 0 ? `Donation :   = ${Number(formData.stayHomeAmount).toFixed(2)} \n` : ``}
                            ${`Tax Deductible Amount :   = ${Number(formData.taxDeductibleAmount).toFixed(2)} \n`}
                            ${sumWithSurcharge!==formData.totalPay?`Surcharge (2%) : = ${(sumWithSurcharge-formData.totalPay).toFixed(2)}\n` :``}
                            ${`Total = ${Number(sumWithSurcharge).toFixed(2)}`} `
    }
    axios
    .post('/api/payment-initiate', paymentRequest)
    .then(response => {

      stripe.confirmCardPayment(
        response.data.paymentIntentKey,
        {
          payment_method: {
            card: elements.getElement(CardElement),
            billing_details: {
              name: formData.cardHolderName,
              email: formData.email,
              phone: formData.phone,
            },
          },
          receipt_email:formData.email
        }
      ).then(function(result) {
        if(result?.error)
        {
          setCardError(result?.error?.message);
          setIsProcessing(false);
          return;
        }

       var paymentNotificationRequest={
        CustomerDetails:{
          GivenNames:formData.firstName,
          FamilyName:formData.familyName,
          EmailAddress:formData.email,
          Phone:formData.phone,
        },        
        EventName:'gala-dinner-2024',  
        Company:formData.company,
        CompanyAddress:formData.companyAddress,          
        BaseUrl:window.location.hostname,
        RequestAccount:"MazdaFoundation",
        PaymentId:result.paymentIntent.id
       }
       axios
       .post('/api/payment-notify', paymentNotificationRequest)
       .then(res=>{
         // Update googel forms
         if((formData.formType==="dealer") && (formData.businessGuests.length) ){
          updateBusinessGuestGoogleForm(formData);
         }
         nextStep();
         setIsProcessing(false);
        });
      }) .catch(error => {
        setCardError('Unexpected Error Occurred. Please try again.');
        setIsProcessing(false);
      });
    })
    .catch(error => {
      setCardError('Unexpected Error Occurred. Please try again.');
      setIsProcessing(false);
    })
         
  }
  return (
    <>
      <CardElement
        onChange={onChange}
        options={{
          style: {
            base: {
              fontSize: '16px',
              color: '#424770',
              '::placeholder': {
                color: '#aab7c4',
              },
            },
            invalid: {
              color: '#9e2146',
            },
            hidePostalCode: true,
          },
        }} />
        <div className="form-field-error">{cardError}</div>
        <br/>
        <div >
        
        <SpinnerButton                  onclick={makePayment}
                                        className='pay-button'
                                        isProcessing={isProcessing}
                                        processingMsg={`Processing A$ ${sumWithSurcharge||0}`}
                                        actionMsg={`Pay A$ ${sumWithSurcharge||0}`}></SpinnerButton>
        {isAmex && 'If you are paying by Amex, an extra 2% will be charged.' }
        </div>
      
    </>
  )
}