
import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from 'formik';
import { StripePayment } from '../components/stripe-payment'



export const EventPayment = ({ formData, setFormData, nextStep, prevStep }) => {
    const updateCardName=(e)=>{
        
        setFormData({...formData,cardHolderName:e.target.value})
    }
    useEffect(() => {
        window.scrollTo(0, 0)
      }, []);
    return (

        <div >
            <div className="container">
                <div className="columns">
                    <div className="one_half column div-table ">
                        <a href="#" className="RedLinkButton" onClick={prevStep}>Back</a>
                        <div className="columns row" >
                            <div className=" column div-table-row"> </div>
                            <div className=" column div-table-row">
                                <div className="div-table-col">Number of table(s)</div>
                                {formData.formType==="supplier" &&
                                    <div>
                                    <div className="div-table-col-center">  {formData.noOfTables} * ${formData.costPerTableSupplier}</div>
                                    <div className="div-table-col   checkout-amount">${formData.noOfTables * formData.costPerTableSupplier} </div>
                                    </div>
                                }
                                {formData.formType==="dealer" &&
                                    <div>
                                    <div className="div-table-col-center">  {formData.noOfTables} * ${formData.costPerTableDealer}</div>
                                    <div className="div-table-col   checkout-amount">${formData.noOfTables * formData.costPerTableDealer} </div>
                                    </div>
                                }
                            </div> 
                        </div>
                        <div className="columns row">
                            <div className=" column div-table-row"></div>
                            <div className=" column div-table-row">
                                <div className="div-table-col">Number of guest(s)</div>
                                {formData.formType==="supplier" &&
                                <div>
                                <div className="div-table-col-center">  {formData.noOfGuests||0} * ${formData.costPerGuestSupplier}</div>
                                <div className="div-table-col   checkout-amount">${formData.noOfGuests * formData.costPerGuestSupplier} </div>
                                </div>
                                }
                                {formData.formType==="dealer" &&
                                <div>
                                <div className="div-table-col-center">  {formData.noOfGuests||0} * ${formData.costPerGuestDealer}</div>
                                <div className="div-table-col   checkout-amount">${formData.noOfGuests * formData.costPerGuestDealer} </div>
                                </div>
                                }
                            </div> 
                        </div>
                        {formData.formType==="dealer" &&
                                <div className="columns row  "> 
                                <div className=" column div-table-row">
                                <div className="div-table-col"> Dealer category donation</div>
                                <div className="div-table-col   checkout-amount">${Number(formData.dealerDonationAmount)||0} </div>
                                </div>
                                </div>
                                }
                        <div className="columns row  ">                         
                            <div className=" column div-table-row">
                                <div className="div-table-col"> Stay-at-home donation  </div>
                                <div className="div-table-col   checkout-amount">${Number(formData.stayHomeAmount.replace('$','').replace('AU','').trim())||0} </div>
                            </div>                                     
                        </div>
                       
                        <div className="columns row total" >           
                            <div className=" column div-table-row">
                                <div className="div-table-col"><b>Subtotal(Inc GST)</b></div>
                                <div className="div-table-col   checkout-amount"><b>${formData.totalPay||0} </b></div>
                            </div>   
                        </div>

                        <div className="columns row">
                            <div className=" column div-table-row">
                                <div className="div-table-col">Donation included</div>
                                <div className="div-table-col   checkout-amount">${formData.taxDeductibleAmount||0} </div>
                            </div>   
                        </div>
                    </div>
                    <div className="one_half column">
                        <div  >
                            <div><h4>PAY WITH CARD</h4></div>
                            <div>
                                <Formik 
                                    initialValues={formData}>
                                    <Form>
                                        <b>Email</b><br />
                                        <Field
                                            name='email'
                                            label='Email Address'
                                            margin='normal'
                                            type="email"
                                            className="email"
                                            readOnly
                                        /><br />
                                        <b>Name on Card</b><br />
                                        <input                                             
                                            type="text"
                                            onChange={updateCardName}
                                            value={formData.cardHolderName}
                                        /><br />
                                        <b>Card Information</b><br />
                                        <StripePayment
                                            formData={formData}  
                                            setFormData={setFormData}
                                            nextStep={nextStep} /><br/>
                                        
                                    </Form>

                                </Formik>
                               
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )

}

