import React, { useState } from "react"

import { EventBanner } from "../../../components/event-banner"
import { ReserveTable } from "../../../components/reserve-table"
import { EventPayment } from "../../../components/event-payment"
import { EventDescription } from "../../../components/event-description"
import BG from "../../../assets/2015/11/MAZD0294-Mazda-Foundation-Website-V4_03.jpg"
import Layout from '../../../components/layout'
import SEO from '../../../components/seo'

const urlParams = typeof window !== 'undefined' ? new URLSearchParams(window.location.search) : null;
const formKey = urlParams?.get('form_key') || ''

const GalaDinner = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    familyName: '',
    email: '',
    position: '',
    company: '',
    companyAddress: '',
    phone: '',
    noOfTables: 0,
    noOfGuests: 0,
    advertise: 0,
    stayHomeAmount: '0',
    stayHomeAmountOption1: '10000',
    stayHomeAmountOption2: '5000',
    stayHomeAmountOption3: '2500',
    stayHomeAmountOption4: '1000',
    costPerTable: '4970',
    costPerGuest: '497',
    costPerTableDealer: '5690',
    costPerGuestDealer: '569',
    taxDeductablePerTableDealer: '2500',
    taxDeductablePerGuestDealer: '250',
    costPerTableSupplier: '5690',
    costPerGuestSupplier: '569',
    taxDeductablePerTableSupplier: '2500',
    taxDeductablePerGuestSupplier: '250',
    costPerTableNoDonation: '5830',
    costPerGuestNoDonation: '583',
    halfPage: '550',
    fullPage: '1100',
    totalPay: 0,
    totalPayWithSurcharge:0,
    taxDeductibleAmount: 0,
    dealerDonationAmount:'0',
    metropolitanDealerDonation: '10000',
    provincialDealerDonation: '5000',
    ruralDealerDonation: '2500',
    taxPerTable: 2000,
    taxPerGuest: 200,
    cardHolderName:'',
    formType:formKey,
    website:'',
    businessGuests: [ ],
    radioAttendYesNo: '',
    radioAttendYes: 'Yes',
    radioAttendNo: 'No'
  });
  const [step, setStep] = useState(1);
  const nextStep = () => setStep(prev => prev + 1);
  const prevStep = () => setStep(prev => prev - 1);
  
  
  switch (step) {
    case 1:
      return (
        
        <Layout page="events">
          <SEO title="Events" />
          
          <div
            id="events"
            style={{
              background: `url(${BG}) repeat`
            }}>
            <EventBanner BannerText="JOIN US FOR AN UNFORGETTABLE EVENING"  />
            <EventDescription />
            <ReserveTable
              formData={formData}
              setFormData={setFormData}
              nextStep={nextStep}
              >
              </ReserveTable>
          </div>
          
        </Layout>
      )
      break;
    case 2:
      return (<Layout page="events">
        <SEO title="Events" />
        <div
          id="events"
          style={{
            background: `url(${BG}) repeat`
          }}>
          <EventBanner BannerText="ORDER SUMMARY" />
          <EventPayment
            formData={formData}
            setFormData={setFormData}
            nextStep={nextStep}
            prevStep={prevStep}
             >
          </EventPayment>
        </div>
      </Layout>)
      break;
    case 3:
      return (<Layout page="events">
        <SEO title="Events" />
        <div
          id="events"
          style={{
            background: `url(${BG}) repeat`
          }}></div>
        <EventBanner BannerText="THANK YOU FOR YOUR BOOKING"  />
        <div className="columns center" >
          <div className="column"><br/>
            Thank you for your reservation for the Mazda Foundation Dinner 2024.<br/><br/>
            We will contact you closer to the event for your guest names and dietary requirements. 
          <br/><br/>
          </div>
          {formData.formType === "dealer" || formData.formType === "supplier" ?
              <div className="column">
                <br />
                Mazda has reserved accommodation at two Crown Hotels. Please
                select the hotel you would like to book:
                <br />
                <br />
                <button className="ConfirmationLinkButton" onClick={()=>window.open('https://reservations.travelclick.com/73179?groupID=4430671','_blank')}>CROWN METROPOL</button><br/><br/>
                <button className="ConfirmationLinkButton" onClick={()=>window.open('https://reservations.travelclick.com/3512?groupID=4195721','_blank')}>CROWN TOWERS</button><br/><br/>
                
                 If you have any questions or issues please email <a className="RedLinkButton" href="mailto:mfdinner@mazda.com.au">mfdinner@mazda.com.au</a><br />
                <br />
              </div>
             : null}
          <div className="column">
            We look forward to seeing you and your guests on the night.<br/><br/>
            <button className="GreyButton" onClick={()=>window.location.href='/'}>BACK TO HOMEPAGE</button><br/><br/>
          </div>
          </div>
      </Layout>)
      break;
  }
}

export default GalaDinner
